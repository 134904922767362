import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./HelpAndSupport.css";
import { useTranslation } from "react-i18next";

const HelpAndSupport = () => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <button
              onClick={handleShow}
              className={`btn btn-primary btn-help-support ${i18n.language == "en" ? "btn-sideRight" : "btn-sideleft" }`}
              // className="btn btn-primary btn-sideleft"
              // style={{left: i18n.language == "en" ? "-45px" : ""}}
              id="modal_view_left"
              data-toggle="modal"
              data-target="#get_quote_modal"
            >
              {" "}
              {t('helpSupport')}
            </button>
          </div>
        </div>
      </div>
      {/* -----Model----- */}
      <Modal
        show={show}
        onHide={handleClose}
        className={`modal modal_outer ${i18n.language == "en" ? "right_modal" : "left_modal" } fade`}
        id="get_quote_modal"
        tabIndex={-1}
        style={{paddingRight: 0}}
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <form method="post" id="get_quote_frm">
            <div className="modal-content ">
              <div className={`modal-header ${i18n.language == "en" ? "en" : ""}`}>
                <h3 className="modal-title"> {t('helpSupport')} </h3>
                <span
                  className="close-button close ms-2"
                  variant="secondary"
                  onClick={handleClose}
                >
                  &times;
                </span>
              </div>
              <div className="modal-body get_quote_view_modal_body">
                <div className="list-group list-support">
                  <Link
                  style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                   to="/help-and-support/faq" className={`list-group-item ${i18n.language == "en" ? "text-start" : ""}`} onClick={handleClose}>
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-question`}></i>
                     {t('help_support.faq')}{" "}
                  </Link>
                  <Link
                  style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                   to="/help-and-support/beneficiary-voice" className={`list-group-item ${i18n.language == "en" ? "text-start" : ""}`}
                   onClick={handleClose}>
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-user-check`}></i>
                    {t('help_support.beneficiary_voice')}{" "}
                  </Link>
                  <Link
                  style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                   to={`${process.env.REACT_APP_DARAH_PORTAL_URL}searchgate`} state={{ isPublicSearch: false, searchKey: null }} className={`list-group-item ${i18n.language == "en" ? "text-start" : ""}`}
                   onClick={handleClose}>
                    {" "}
                    
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-file-search`}></i>
                    {t("help_support.search_gate")}
                  </Link>
                  <Link
                  style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                   to={"/help-and-support/accessibility-services"} className={`list-group-item ${i18n.language == "en" ? "text-start" : ""}`}
                   onClick={handleClose} >
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-wheelchair`}></i>
                     {t('help_support.accessibility_services')}{" "}
                  </Link>
                  {/* <Link
                  style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                   to={"/help-and-support/signlanguage"} className={`list-group-item ${i18n.language == "en" ? "text-start" : ""}`}
                   onClick={handleClose}>
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-american-sign-language-interpreting`}></i>{" "}
                    {t('help_support.sign_lang')}{" "}
                  </Link> */}
                  <Link
                  style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                   to={"/help-and-support/contact-us"} className={`list-group-item ${i18n.language == "en" ? "text-start" : ""}`}
                   onClick={handleClose}>
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-phone`}></i>
                    {t('help_support.contact_us')}{" "}
                  </Link>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
            {/* <!-- //modal-content --> */}
          </form>
        </div>
        {/* <!-- modal-dialog --> */}
      </Modal>
    </>
  );
};
export default HelpAndSupport;
