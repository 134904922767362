// import {menuItems} from "./MenuData/menuItems";
import MenuItems from "./MenuItems";
import { Navbar } from "react-bootstrap";
import NavbarIcons from "./NavbarIcons";
import { Link, useNavigate } from "react-router-dom";
import redLogo from "../../assets/images/redLogo.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import NavbarIconsMobileScreen from "./NavbarIconsMobileScreen";

const NavbarContainer = ({ links }) => {
  const { t, i18n } = useTranslation();
  const [collapseMenu, setCollapseMenu] = useState(false);
  const navigate = useNavigate()

  let langSelected = localStorage.getItem("i18nextLng");
  if (langSelected === "en-US" || langSelected === "") {
    localStorage.setItem("i18nextLng", "ar");
    window.location.reload();
  }

  const menuItems = [
    {
      title: t("home"),
      url: "/",
    },
    {
      title: t("aboutMagazine"),
      url: "/about-journal",
    },
    {
      title: t("subscriptions"),
      url: "/subscriptions",
    },
    {
      title: t("editors"),
      url: "/editorial-board",
    },
    {
      title: t("darahPublications"),
      url: "/journals",
    },
  ];

  return (
    <>
      <Navbar
        variant="dark"
        expand="md"
        className="p-0 hideSmScreen"
        style={{ direction: i18n.language == "en" ? "ltr" : "rtl" }}
        expanded={collapseMenu}
      >
        <Navbar.Brand className="p-0" style={{ height: "48px" }} role="button">
          <img
            onClick={() => navigate("/")}
            style={{
              paddingRight: i18n.language == "en" ? "25px" : "",
              marginRight: i18n.language == "en" ? "25px" : "",
              paddingLeft: i18n.language == "en" ? "0" : "25px",
              marginLeft: i18n.language == "en" ? "0" : "25px",
            }}
            alt="الدارة"
            src={links?.image?.logo ? links?.image?.logo : redLogo}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="nav navbar-nav ml-auto">
            {menuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  setCollapseMenu={setCollapseMenu}
                />
              );
            })}
          </ul>
        </Navbar.Collapse>
        <NavbarIcons links={links} />
      </Navbar>

      <Navbar
        variant="dark"
        expand="md"
        expanded={collapseMenu}
        className="p-0 hideLgScreen"
        style={{ direction: i18n.language == "en" ? "ltr" : "rtl" }}
      >
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="d-flex justify-content-center align-items-center p-0"
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "30px", width: "30px" }}
          >
            {collapseMenu ? (
              <>
                <i
                  onClick={() => setCollapseMenu(!collapseMenu)}
                  role="button"
                  style={{ color: "#231F20" }}
                  className={`fas fa-times fs-3`}
                ></i>
              </>
            ) : (
              <>
                <i
                  onClick={() => setCollapseMenu(!collapseMenu)}
                  role="button"
                  style={{ color: "#231F20" }}
                  className={`fas fa-bars fs-3`}
                ></i>
              </>
            )}
          </div>
        </Navbar.Toggle>

        <Navbar.Brand
          className="p-0 navBrand align-items-center d-flex"
          style={{ height: "48px" }}
          role="button"
        >
          <img
            onClick={() => navigate("/")}
            className="py-1"
            alt="الدارة"
            src={links?.image?.logo ? links?.image?.logo : redLogo}
          />
        </Navbar.Brand>
        <div className="left-list-icon">
          <Link
            to={"/Search-in-magazine"}
            className="d-flex justify-content-between align-items-center mx-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="#854239"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="#854239"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="nav navbar-nav">
            {menuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  setCollapseMenu={setCollapseMenu}
                />
              );
            })}
            <NavbarIconsMobileScreen />
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavbarContainer;
