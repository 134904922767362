import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Footer.module.css";
import logo2030 from "../../assets/images/Saudi_Vision_2030_logo.png";
import QRImage from "../../assets/images/QR.png";
import googlePlay from "../../assets/images/googlePlay.png";
import AppleStore from "../../assets/images/AppleStore.png";
import FooterList from "./FooterList";
import { Link } from "react-router-dom";
import redLogo from "../../assets/images/redLogo.png";
import { useTranslation } from "react-i18next";

const Footer = ({ links }) => {
  const { t, i18n } = useTranslation();


  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer-main"]}>
        <Container>
          <Row
            className={`justify-content-between align-items-center ${
              i18n.language === "en" ? "flex-row-reverse" : ""
            }`}
          >
            {/* <Col lg={3} md={12} className={"d-flex align-items-center justify-content-center mb-md-5 mb-3 mb-xl-0"}>
              <div className="d-flex align-items-center justify-content-center">
                <Link to={'/'}><img
                  loading="lazy"
                  width="70px"
                  className={styles["footer-logo"]}
                  src={links?.image?.logo || redLogo}
                  alt="Constra"
                  /></Link>
                    
                <div className={`${styles.betweenLogo} mx-3`}></div>
                <div className={styles.logo2030}>
                  <img src={links?.image?.default || logo2030} alt="2030"></img>
                </div>
              </div>
            </Col> */}
            <Col
              lg={3}
              md={12}
              className={
                "d-flex align-items-center justify-content-center mb-md-5 mb-3 mb-xl-0"
              }
            >
              <div
                className={`d-flex justify-content-center justify-content-lg-start align-items-center ${
                  i18n.language === "en" ? "flex-row-reverse" : ""
                }`}
              >
                <Link to={"/"}>
                  <img
                    loading="lazy"
                    width="70px"
                    className={styles["footer-logo"]}
                    src={links?.image?.logo || redLogo}
                    alt="Constra"
                  />
                </Link>

                <div className={`${styles.betweenLogo} mx-3`}></div>
                <div className={styles.logo2030}>
                  <img src={links?.image?.default || logo2030} alt="2030"></img>
                </div>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              className={
                "mt-3 mt-xl-0 mb-3 mb-md-0 d-flex align-items-center justify-content-center"
              }
              style={{
                flexDirection: i18n.language == "en" ? "row-reverse" : "row",
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <Link className={styles.footerLinks} to={"/help-and-support/contact-us"}>
                  {t(`call_us`)}
                </Link>
                <div
                  style={{ height: "24px" }}
                  className={`${styles.betweenLogo} mx-2`}
                ></div>
                <Link className={styles.footerLinks} to={"/help-and-support/faq"}>
                  {t(`nav_items.faq`)}
                </Link>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              className={
                "mt-3 mt-xl-0 mb-3 mb-md-0 d-flex flex-column align-items-center justify-content-center"
              }
            >
              <div className={`${styles["working-hours"]}`}></div>
              <div className={styles["footer-social"]}>
                <ul className="d-flex">
                  {i18n.language == "ar" ? (
                    <>
                      {" "}
                      {links?.facebook && (
                        <>
                          <li>
                            <a
                              href={links?.facebook}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="Facebook"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.twitter && (
                        <>
                          <li>
                            <a
                           className={styles.svgIcon}
                            style={{width: "35px", height: "35px", }}
                            href={links.twitter}
                            target={"_blank"}
                            rel="noreferrer noopener"
                            aria-label="Twitter"
                        >
                            <svg className="mt-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
                            <path d="M8.22232 6.01792L13.0006 0.583252H11.8687L7.71797 5.30113L4.40526 0.583252H0.583496L5.59406 7.7182L0.583496 13.4166H1.7154L6.09584 8.43328L9.59506 13.4166H13.4168M2.12392 1.41843H3.86284L11.8678 12.6224H10.1285" fill="#231F20"/>
                            </svg>
                        </a>
                          </li>
                        </>
                      )}
                      {links?.instagram && (
                        <>
                          <li>
                            <a
                              href={links?.instagram}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="Instagram"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.youtube && (
                        <>
                          <li>
                            <a
                              href={links?.youtube}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="youtube"
                            >
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.googleplus && (
                        <>
                          <li>
                            <a
                              href={links?.googleplus}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="googleplus"
                            >
                              <i className="fab fa-google-plus"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.email && (
                        <>
                          <li>
                            <a
                              href={"mailto:" + links?.email}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="email"
                            >
                              <i className="fas fa-envelope"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.fax && (
                        <>
                          <li>
                            <a
                              href={"fax: +" + links?.fax}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="fax"
                            >
                              <i className="fas fa-fax"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.phone && (
                        <>
                          <li>
                            <a
                              href={"tel:+" + links.phone}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="phone"
                            >
                              <i className="fa fa-phone"></i>
                            </a>
                          </li>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {links?.phone && (
                        <>
                          <li>
                            <a
                              href={"tel:+" + links.phone}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="phone"
                            >
                              <i className="fa fa-phone"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.fax && (
                        <>
                          <li>
                            <a
                              href={"fax: +" + links?.fax}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="fax"
                            >
                              <i className="fas fa-fax"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.email && (
                        <>
                          <li>
                            <a
                              href={"mailto:" + links?.email}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="email"
                            >
                              <i className="fas fa-envelope"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.googleplus && (
                        <>
                          <li>
                            <a
                              href={links?.googleplus}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="googleplus"
                            >
                              <i className="fab fa-google-plus"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.youtube && (
                        <>
                          <li>
                            <a
                              href={links?.youtube}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="youtube"
                            >
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                        </>
                      )}
                      {links?.instagram && (
                        <>
                          <li>
                            <a
                              href={links?.instagram}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="Instagram"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </>
                      )}

                      {links?.twitter && (
                         <>
                         <li>
                           <a
                           className={styles.svgIcon}
                           style={{width: "35px", height: "35px", }}
                           href={links.twitter}
                           target={"_blank"}
                           rel="noreferrer noopener"
                           aria-label="Twitter"
                       >
                           <svg className="mt-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
                           <path d="M8.22232 6.01792L13.0006 0.583252H11.8687L7.71797 5.30113L4.40526 0.583252H0.583496L5.59406 7.7182L0.583496 13.4166H1.7154L6.09584 8.43328L9.59506 13.4166H13.4168M2.12392 1.41843H3.86284L11.8678 12.6224H10.1285" fill="#231F20"/>
                           </svg>
                       </a>
                         </li>
                       </>
                      )}
                      {links?.facebook && (
                        <>
                          <li>
                            <a
                              href={links?.facebook}
                              target={"_blank"}
                              rel="noreferrer noopener"
                              aria-label="Facebook"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <hr className={styles.footerHr} />
          </Row>
          <Row>
            <Col className={`${styles.rights} text-center`}>
              {t(`right`)} &#169; {new Date().getFullYear()}
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
export default Footer;
