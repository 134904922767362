import styles from "./Layout.module.css";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ChatWhatsApp from "../../components/ChatWhatsApp/ChatWhatsApp";
import HelpAndSupport from "../../components/HelpAndSupport/help-and-support";
import { getSettingsData } from './../../api/services/layout&settings/settings';
import { getHomeData } from "../../api/services/layout&settings/home";
import { getMenuData } from "../../api/services/layout&settings/journals-menu";

const Layout = () => {
  const [menuSlug, setMenuSlug] = useState([]);
  const [settingsData, setSettingsData] = useState([]);

  useEffect(() => {
    getMenuData()
      .then((res) => {
        setMenuSlug(res?.data?.data);
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    getSettingsData()
    .then((res) => {
      setSettingsData(res.data);
      })
      .catch((err) => {
        setError("Some error happened!");
      });
  }, []);

  return (
    <>
      <Header links={settingsData} />
      <div className={styles.bodyInner}>
        <HelpAndSupport />
        <Outlet />
      </div>
      {/* <ChatWhatsApp /> */}
      <a href={"https://wa.me/" + settingsData?.whatsapp?.replace(/\D/g, "")}
      target={"_blank"}
      aria-label="phone"
      >
        <ChatWhatsApp/>
      </a>
      <Footer links={settingsData} />
    </>
  );
};

export default Layout;
