import "./header.css";
import NavbarContainer from "./NavbarContainer";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Header = ({ links }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 45);
    });
  }, []);
  return (
    <>
      {/* <!-- Header start --> */}
      <header
        className="header-one"
        style={{ marginBottom: scroll ? "73px" : "0" }}
      >
        <div className={"site-navigation " + (scroll ? "navbar-fixed" : "")}>
          <Container fluid>
            <NavbarContainer links={links} />
          </Container>
        </div>
      </header>
    </>
  );
};

export default Header;
