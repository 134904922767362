import { axios, baseURL, config, setSlug } from "../../axios";

export const getMenuData = async () => {
    try {
        const response = await axios(baseURL + `journals/menu`, config);
        setSlug(response?.data?.data?.data[0]?.slug)
        return response.data;
    } catch (error) {
        console.log(error);
    }
};