import NotFound404 from '../../assets/images/404NotFound.webp';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Notfound = () => {
  const {t} = useTranslation();

  return (
      <center>
          <Container className={"mt-3"}>
              <Row className={"w-25"}>
                  <Link to={"/"}>
                      <Button className={"py-1 py-md-3 fs-5"} variant={"primary"}>{t("back")}</Button>
                  </Link>
              </Row>
              <Row>
                  <img src={NotFound404} alt="Image Not Found"/>
              </Row>
          </Container>
      </center>
  );
};
export default Notfound;
