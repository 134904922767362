import axios from "axios";
import i18next from "i18next";
// TODO: change baseURL to http://darah-dev.4explain.net/api/v1/ and add stores to all api except auth
const baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;
const login_baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

const config = {
  headers: {
    Accept: "application/json",
    "Accept-Language": `${i18next.language}`,
    "Client-Secret": "#da565rah4HHJ5$%gtwe22134gKLMno123",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token")}`;
      },
    },
  },
};

// To get general slug on open app 
let SLUG = '';
function setSlug(slug) {
  return SLUG = slug
}

export { axios, baseURL, login_baseURL, config, SLUG, setSlug };
