import { Link } from "react-router-dom";
import styles from "./FooterList.module.css";
import {MdOutlineKeyboardArrowLeft} from "react-icons/md";
import { useTranslation } from "react-i18next";

const FooterList = (props) => {
  //  TODO: change "to" in Link
  const { t, i18n } = useTranslation();

  return (
    <>
      <h3 style={{textAlign:i18n.language=="ar"?"right":"left"}} className={i18n.language=="ar"?styles["widget-title-ar"]:styles["widget-title-en"]} >{props.data.title}</h3>
      <ul className={i18n.language=="ar"?styles["list-arrow-ar"]:styles["list-arrow-en"]}>
        {props.data.items.map(item => {
          return (
              <>
                  <li key={item.id} style={{textAlign:i18n.language=="ar"?"right":"left"}}>
                      {item.url ? <Link to={item.url}>{item.title}</Link> : <a href={item.href}>{item.title}</a>}
                  </li>
              </>
          );
        })}
      </ul>
    </>
  );
};

export default FooterList;
