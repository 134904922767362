import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../api/axios";


const NavbarIconsMobileScreen = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (newLanguage) => {
      if (newLanguage !== language) {
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        config.headers["Accept-Language"] = i18n.language;
        window.location.reload();
      }
    };
  
  return (
    <li className={`menu-items`}>
    <Dropdown className={`d-flex flex-column justify-content-${i18n.language == "en" ? "left" : "right"}`}>
        <Dropdown.Toggle className={"d-flex justify-content-between align-items-center p-0 py-1 my-1"}>
        {i18n.language == "en" ? "Language" : "اللغة"}
        <span className="d-flex align-items-center justify-content-center"><i className="fas fa-angle-down fa-fw align-middle"></i></span>

        </Dropdown.Toggle>
        {language === 'en' ? (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguageChange('ar')}>العربية</Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguageChange('ar')}>العربية</Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </li>
  );
};

export default NavbarIconsMobileScreen;
