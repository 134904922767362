import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config } from "../../api/axios";

const NavbarIcons = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    
    const handleLanguageChange = (newLanguage) => {
      if (newLanguage !== language) {
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        config.headers["Accept-Language"] = i18n.language;
        window.location.reload();
      }
    };
  
  return (
    <div className="left-list-icon">
      <Link to={"/Search-in-magazine"} className="d-flex justify-content-between align-items-center mx-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
          <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22 22L20 20" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </Link>

      <Dropdown className={i18n.language == "ar" ? "localizationDropDown" : "localizationDropDownEn"} drop={'down-start'} id={`dropdown-button-drop-down-start`}>
        <Dropdown.Toggle className={"d-flex justify-content-between align-items-center p-0 my-0"}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.00156 3H9.00156C7.05156 8.84 7.05156 15.16 9.00156 21H8.00156" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3 9C8.84 7.05 15.16 7.05 21 9" stroke="#854239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </Dropdown.Toggle>
        {language === 'en' ? (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguageChange('ar')}>العربية</Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguageChange('ar')}>العربية</Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>

    </div>
  );
};

export default NavbarIcons;
