import './chatwhatsapp.css';
import user from '../../assets/images/user.jpg';
import { FloatingWhatsApp } from "react-floating-whatsapp";
import i18n from './../../i18next';

const ChatWhatsApp = () => {
    return (
        <FloatingWhatsApp
            phoneNumber="+966 11 401 1999"
            accountName="خدمات الزوار"
            statusMessage
            allowEsc
            allowClickAway
            notification
            notificationSound
            avatar={user}
            chatMessage={<>السلام عليكم 👋 <br /><br /> ... كيف يمكنني مساعدتك</>}
            placeholder="اكتب رسالتك هنا"
            buttonClassName={`floatingChat enter whatsappSize ${i18n.language == "en" ? "en" : "" }`}
            chatboxClassName={"chatBox popUpChat"}
        />
    );
};

export default ChatWhatsApp;