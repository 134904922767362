import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout/Layout";

const HomePageLazyLoading = React.lazy(() => import("./pages/HomePage/HomePage"));

const MagazineGatesLazyLoading = React.lazy(() => import("./pages/MagazineGates/magazine-gates"));
const SubscriptionsLazyLoading = React.lazy(() => import("./pages/Subscriptions/subscriptions"));

const JournalsLazyLoading = React.lazy(() => import("./pages/journals/journals"));

// Start Editorial
const EditorLazyLoading = React.lazy(() => import("./pages/Magazine/editors"));
const AuthorWorkLazyLoading = React.lazy(() => import("./pages/Magazine/editor-work"));
const AuthorCVLazyLoading = React.lazy(() => import("./pages/Magazine/editor-cv"));
// End Editorial

// Start Articles Common Details
const AllArticlesDetailsLazyLoading = React.lazy(() => import("./pages/ArticleDetails/AllArticlesDetails"));
// End Articles Common Details

// Start Help&Support
const FAQLazyLoading = React.lazy(() => import("./pages/FAQ/FAQ"));
const BeneficiaryVoiceLazyLoading = React.lazy(() => import("./pages/BeneficiaryVoice/beneficiary-voice"));
const SignLanguageLazyLoading = React.lazy(() => import("./pages/SignLanguage/SignLanguage"));
const ServicesForPeopleLazyLoading = React.lazy(() => import("./pages/Services-for-people/Services-for-people"));
const ContactUsLazyLoading = React.lazy(() => import("./pages/ContactUs/ContactUs"));
// End Help&Support

// Common
const SearchGateLazyLoading = React.lazy(() => import('./pages/SearchGate/search-gate'));
const PrivacyPolicyLazyLoading = React.lazy(() => import('./pages/Privacy-Policy/Privacy-Policy'));``
import Notfound from "./pages/Notfound/Notfound";
import ScrollToTop from "./common/ScrollToTop/ScrollToTop";
import { useZoom } from './context/zoomContext/ZoomContext'
import { useContrast } from './context/contrastContext/ContrastContext'
import React from "react";
import BlankPageLoader from "./components/PageLoader/blank-page-loader";


function App() {
  const { zoomLevel } = useZoom();
  const { contrastLevel } = useContrast();
  return (
    <div className={`zoomed contrasted`}>
      <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route element={<Layout />}>
              {/* <Route path="/" element={<HomePage />} /> */}

              <Route path="/" element={<React.Suspense fallback={<BlankPageLoader />}><HomePageLazyLoading /></React.Suspense>} />
              <Route path="/about-journal" element={<React.Suspense fallback={<BlankPageLoader />}><MagazineGatesLazyLoading /></React.Suspense>} />
              <Route path="/subscriptions" element={<React.Suspense fallback={<BlankPageLoader />}><SubscriptionsLazyLoading /></React.Suspense>} />
              <Route path="/journals" element={<React.Suspense fallback={<BlankPageLoader />}><JournalsLazyLoading /></React.Suspense>} />

              {/* Start Editorial Board */}
              <Route path="/editorial-board" element={<React.Suspense fallback={<BlankPageLoader />}><EditorLazyLoading /></React.Suspense>} />
              <Route path="/editorial-board/autherwork/:userName" element={<React.Suspense fallback={<BlankPageLoader />}><AuthorWorkLazyLoading /></React.Suspense>} />
              <Route path="/editorial-board/authercv/:userName" element={<React.Suspense fallback={<BlankPageLoader />}><AuthorCVLazyLoading /></React.Suspense>} />
             {/* End Editorial Board */}

              {/* Start Articles Common Details */}
              <Route path="/articles-article-details/:slug" element={<React.Suspense fallback={<BlankPageLoader />}><AllArticlesDetailsLazyLoading /></React.Suspense>} />
              <Route path="/articles-article-details/:slug/article/:articleSlug" element={<React.Suspense fallback={<BlankPageLoader />}><AllArticlesDetailsLazyLoading /></React.Suspense>} />
              <Route path="/articles-article-details/:slug/editor/:editorSlug" element={<React.Suspense fallback={<BlankPageLoader />}><AllArticlesDetailsLazyLoading /></React.Suspense>} />
              {/* End Articles Common Details */}

            {/* Start Help&Support */}
              <Route path="/help-and-support/contact-us" element={<React.Suspense fallback={<BlankPageLoader />}><ContactUsLazyLoading /></React.Suspense>} />
              <Route path="/help-and-support/beneficiary-voice" element={<React.Suspense fallback={<BlankPageLoader />}><BeneficiaryVoiceLazyLoading /></React.Suspense>} />
              {/* <Route path="/help-and-support/signlanguage" element={<React.Suspense fallback={<BlankPageLoader />}><SignLanguageLazyLoading /></React.Suspense>} /> */}
              <Route path="/help-and-support/faq" element={<React.Suspense fallback={<BlankPageLoader />}><FAQLazyLoading /></React.Suspense>} />
              <Route path="/help-and-support/accessibility-services" element={<React.Suspense fallback={<BlankPageLoader />}><ServicesForPeopleLazyLoading /></React.Suspense>} />
             {/* End Help&Support */}

             <Route path="/Search-in-magazine" element={<React.Suspense fallback={<BlankPageLoader />}><SearchGateLazyLoading /></React.Suspense>} />
             <Route path="/privacy-policy" element={<React.Suspense fallback={<BlankPageLoader />}><PrivacyPolicyLazyLoading /></React.Suspense>} />
              
            </Route>

            <Route path="*" element={<Notfound />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
